$(document).ready(function() {
    $('#show-private').on('click', function () {
        $('#subscribe-business').slideUp()
        $('#subscribe-private').slideDown()
    });

    $('#show-business').on('click', function () {
        $('#subscribe-private').slideUp()
        $('#subscribe-business').slideDown()
    });

});