$(document).ready(function () {

    if (window.location.search != '') {
        if (document.getElementById('shoptype') != undefined) {
            var type = GetUrlValue('business');
            document.getElementById('shoptype').value = 'type:' + type;
        }
    }

    // show hide password recovery
    $('.js-toggle-show').on('click', function (event) {
        event.preventDefault();
        var $this = $(this);

        $($this.attr('data-show')).slideDown();
        $($this.attr('data-hide')).slideUp();
    });

    // login form
    $('#login-form').validate({
        rules: {
            username: 'required',
            password: 'required'
        }
    });

    // password recovery form
    $('#password-recover-form').validate({
        rules: {
            email: 'required',
            password: 'required',
            recoverpassword2: {
                required: true,
                equalTo: '#recoverpassword'
            }
        },
        messages: {
            recoverpassword2: {
                equalTo: msg['ERR_CONFIRMPASSWORD']
            }
        },
        submitHandler: function (form) {
            const $form = $(form);
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function (data) {
                    $form.find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
                    }
                    else {
                        showMessageBox('danger', msg[data.errors.message] || msg['MSG_GENERIC_ERROR']);
                    }
                },
                failed: function (data) {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function (data) {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
    });


    // subscribe form
    $('#subscribe-form').validate({
        rules: {
            firstname: 'required',
            lastname: 'required',
            username: {
                required: true,
                email: true
            },
            company: 'required',
            cfpiva: 'required',
            password1: 'required',
            password2: {
                required: true,
                equalTo: '#password1'
            }
        },
        messages: {
            password2: {
                equalTo: msg['ERR_CONFIRMPASSWORD']
            }
        },
        errorPlacement: doNotPlaceForCheckbox,
        submitHandler: submitSubscribeForm
    });

    $('#subscribe-form-private').validate({
        rules: {
            firstname: 'required',
            lastname: 'required',
            username: {
                required: true,
                email: true
            },
            password1: 'required',
            password2: {
                required: true,
                equalTo: '#password1_private'
            }
        },
        messages: {
            firstname: msg['ERR_MISSINGFIELDS'],
            lastname: msg['ERR_MISSINGFIELDS'],
            username: {
                required: msg['ERR_MISSINGFIELDS'],
                email: msg['ERR_INVALIDMAIL']
            },
            password1: msg['ERR_MISSINGFIELDS'],
            password2: {
                required: msg['ERR_MISSINGFIELDS'],
                equalTo: msg['ERR_CONFIRMPASSWORD']
            }
        },
        errorPlacement: doNotPlaceForCheckbox,
        submitHandler: submitSubscribeForm
    });

    function doNotPlaceForCheckbox(error, element) {
        if ($(element).attr('name') != 'clausola1') {
            $(error).insertAfter(element);
        }
    }

    function submitSubscribeForm(form) {
        const $form = $(form);

        if ($form.find('[name="clausola1"]').is(':checked')) {
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function (data) {
                    $form.find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        if (window.dataLayer) {
                            pushGTMEventWithCallback(500, {
                                event: 'sign_up',
                                eventCallback: GTMCallback(function() {
                                    window.location.href = $form.data('redirectsuccess');
                                })
                            });
                        }
                        else {
                            window.location.href = $form.data('redirectsuccess');
                        }
                    }
                    else {
                        showMessageBox('danger', msg[data.errors.message]);
                    }
                },
                failed: function (data) {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function (data) {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
        else {
            showMessageBox('danger', msg['ERR_PRIVACY']);
        }
    }

    $('#login-block').slideToggle();
    $('.show-for-login').fadeToggle(0);

    $('.login-toggle').on('click tap', function () {
        $('html, body').animate({
            scrollTop: $(".login-subscribe-container").offset().top
        }, 400);
        $('#subscribe-block, #login-block').slideToggle();
        $('.show-for-login, .show-for-subscribe').fadeToggle(0);
    });

    $('#subscribe-part-private-toggle').on('click tap', function () {
        if ($('#subscribe-form-private-part-1 input').valid()) {
            $(this).toggleClass('btn-primary').toggleClass('btn-secondary');
            $('#subscribe-form-private-part-1, #subscribe-form-private-part-2').slideToggle();
            $('.show-for-part-1-private, .show-for-part-2-private').fadeToggle(0);
        }
    });

    $('#subscribe-part-toggle').on('click tap', function () {
        if ($('#subscribe-form-part-1 input').valid()) {
            $(this).toggleClass('btn-primary').toggleClass('btn-secondary');
            $('#subscribe-form-part-1, #subscribe-form-part-2').slideToggle();
            $('.show-for-part-1, .show-for-part-2').fadeToggle(0);
        }
    });

});

function GetUrlValue(VarSearch) {
    var SearchString = window.location.search.substring(1);
    var VariableArray = SearchString.split('&');
    for (var i = 0; i < VariableArray.length; i++) {
        var KeyValuePair = VariableArray[i].split('=');
        if (KeyValuePair[0] == VarSearch) {
            return KeyValuePair[1];
        }
    }
}
