$(document).ready(function() {

    $('.carrier-form select[name=country_id], .carrier-form select[name=carrier_id]').on('change', function() {
        const form = $('.carrier-form')
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: {
                country_id: form.find('select[name=country_id]').val() || form.find('input[name=country_id]').val(),
                carrier_id: form.find('select[name=carrier_id]').val()
            },
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.reload();
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('#cap_change').on('keydown', function(event) {
        if (event.key == 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            $(this).trigger('change');
        }
    });
    $('#cap_change').on('change', function() {
        const form = $('.carrier-form')
        $.ajax({
            type: 'POST',
            url: '/restful/shopping/public/checkout/saveshipping',
            data: {
                updateType: 'dispatch',
                country_id: form.find('select[name=country_id]').val(),
                cap: form.find('#cap_change').val()
            },
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.reload();
                }
                else {
                    showMessageBox('danger', msg[data.errors.message]);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    })

    $('#country_dispatch').on('change', function() {
        $.ajax({
            type: 'POST',
            url: '/restful/shopping/public/checkout/saveshipping',
            data: $('#dispatch-address-form').serialize(),
            dataType: 'json',
            success: function() {
                window.location.reload();
            },
            error: function(xhr, textStatus, errorThrown) {
                console.log('error');
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.info-vat').on('click', function() {
        $('#modalVat').foundation('open'); 
        $('#modalVat').attr('change', 'false');
    })

    $('.vat-form').on('change', function() {
        var form = $(this);
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    if (form.find('select').val() != 0) {
                        $('#modalVat').foundation('open'); 
                        $('#modalVat').attr('change', 'true');
                    } else {
                        window.location.reload();
                    }
                }
                else {
                    showMessageBox('danger', msg[data.errors.message]);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.close-modalvat').on('click', function() {
        $('#modalVat').foundation('close'); 
    })

    $('#modalVat').on('closed.zf.reveal', function() {
        if ($(this).attr('change') == 'true') {
            window.location.reload();
        }
    })


    // update cart quantity
    $('.cart-update').on('submit', function(event) {
        event.preventDefault();
        const $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    rewixUpdateCart($form);
                    window.location.href = '/current/cart';
                }
                else {
                    if (data.code == 'MIXED_INTANGIBLE') {
                        showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                    }
                    else if (data.code == 'INSUFFICIENT_STOCK') {
                        showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);

                        const inputs = $form.find('.quantity-container').toArray();
                        for (let input of inputs) {
                            const $input = $(input);
                            if ($input.val() > $input.data('max')) {
                                $input.css('color', 'red');
                            }
                        }
                    }
                    else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                        showMessageBox('danger', msg['MSG_RULE_REJECT'].replace(/:rule/g, data.rejectCartRuleName))
                    }
                    else if (data.code == 'MIXED_CART') {
                        showMessageBox('danger', msg['MSG_MIXED_CART']);
                    }
                    else if (data.code == 'LOGIN_REQUIRED') {
                        location.href = '/' + $('html').attr('lang') + '/login';
                    }
                    else { 
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $('[data-over-max]').on('change', overMaxCheck);
    function overMaxCheck() {
        const $input = $(this);
        if ($input.val() <= $input.data('max')) {
            $input.css('color', '');
        }
    }


    // remove cart size
    $('.btn-cart-remove').on('click', function(event) {
        event.preventDefault();
        const $this = $(this);
        var data = {};
        data[$this.attr('data-name')] = 0;
        $.ajax({
            type: 'POST',
            url: $this.attr('data-action'),
            data: data,
            success: function(data) {
                const container = $this.closest('.qty-input-container');
                rewixUpdateCart(container, true);
                showMessageBox('success', msg['MSG_REMOVEDFROMCART'])
                setTimeout(function(){ window.location.href = '/current/cart'; }, 1000);
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });


    // Coupon form
    $(".burn-coupon-form").on('submit', function(event) {
        event.preventDefault();
        if ($(this).find("input[name=code]").val().length > 0) {
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_COUPON_VALID']);
                        window.location.href = "/current/cart";
                    } else {
                        showMessageBox('danger', msg['MSG_COUPON_ERROR']);
                    }
                }
            });
        } else {
            showMessageBox('danger', msg['MSG_COUPON_ERROR']);
        }
    });

    $('#gestpay-form').on('submit', function(e) {
        e.preventDefault();
        var action = $(this).attr('action');
        $.ajax({
            type : 'POST',
            url : action,
            async: false,
            beforeSend : function(xhr) {
                xhr.setRequestHeader("Accept", "application/json")
            },
            dataType : "json",
            success : function(data){
                if (data.success) {
                    if (window.dataLayer) {
                        dataLayer.push({ ecommerce: null });
                        pushGTMEventWithCallback(500, {
                            event: 'add_payment_info',
                            ecommerce: window.ecommerce('Gestpay'),
                            eventCallback: GTMCallback(function() {
                                window.location.href = data.message;
                            })
                        });
                    }
                    else {
                        window.location.href = data.message;
                    }
                }
            }
        });
    });


    // calculate each product total
    function singleProductQty() {
        $('.cart-product').each(function () {
            var inputs = $(this).find('.i-number');
            var total = 0;

            inputs.each(function () {
                total += parseInt($(this).val());
            });

            $(this).find('.single-product-qty').text(total);
        });
    }


    // calculate total quantity
    function totalQty() {
        var total = 0;

        $('.single-product-qty').each(function () {
            total += parseInt($(this).text());
        });

        $('#total-qty').text(total);
    }

    // calculate total quantity
    function totalDiscount() {
        var total = 0;

        $('.discount-value').each(function () {
            var currentValue = parseFloat($(this).text()).toFixed(2);
            total = parseFloat(total) + parseFloat(currentValue);
        });

        $('#total-discount').text(parseFloat(total).toFixed(2) + "€");
    }


    singleProductQty();
    totalQty();
    totalDiscount();

    $('.close-popup').on('click', function() {
        $('#addToCartComplete').foundation('close');
    })

    $('.cart-remove-all-products').on('click', function(event) {
        event.preventDefault();
        showQuestionBox(msg['MSG_DELETEALLPRODUCTS'], null, 'removeAllProducts()')
    });
});

function removeAllProducts() {
    $('div.alert-box').html('');
    $('.alert-box').hide()
    $('body').removeClass('block-scroll')
    
    let data = {};
    $('.cart-product').each(function() {
        var currentRow = $(this).find('.qty-input-container');
        var inputs = $(currentRow).find('.i-number');

        inputs.each(function () {
            data[$(this).attr('name')] = 0;
        });
    });

    $.ajax({
        type: 'POST',
        url: '/restful/shopping/public/checkout/addtocart',
        data: data,
        dataType: 'json',
        success: function(data) {
            if (data.success) {
                const container = $('#cart-container');
                rewixUpdateCart(container, true);
                window.location.href = '/current/cart';
            }
            else {
                showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
            }
        },
        error: function() {
            showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
        }
    });
}