$(document).ready(function() {
  
  $(document).foundation();

  $('.product-slider-best').slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          dots: true,
          autoplaySpeed: 4000
        }
      },
      {
        breakpoint: 600,
        settings: {
          autoplay: true,
          dots: true,
          autoplaySpeed: 4000,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          dots: true,
          autoplay: true,
          autoplaySpeed: 4000,
          slidesToScroll: 2
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  $('.product-slider-cms').slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          dots: true,
          autoplaySpeed: 4000
        }
      },
      {
        breakpoint: 600,
        settings: {
          autoplay: true,
          dots: true,
          autoplaySpeed: 4000,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          dots: true,
          autoplay: true,
          autoplaySpeed: 4000,
          slidesToScroll: 2
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  $('#best-brands').slick({
    infinite: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    pauseOnFocus: false,
    arrows: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              arrows: false,
              dots: true
            }
        },
        {
          breakpoint: 640,
          settings: {
              slidesToShow: 1,
              dots: true
          }
        }
    ]
  });

  $('#slider-home').slick({
    speed: 500,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 4500,
    focusOnSelect: false,
    adaptiveHeight: true,
    pauseOnFocus: false,
  });
    

  if ($('.video').length > 0) {
    $('.video').height($('.video').width()/1.723)
  }

  // search
  const categorySelectorSlideDuration = 250;
  $('#mainsearch-mobile').on('focusin', function() {
    $(this).closest('form').find('.mobile-category-picker').slideDown(categorySelectorSlideDuration);
  });
  $('.body-container').on('click', function() {
    $('#mainsearch-mobile').closest('form').find('.mobile-category-picker').slideUp(categorySelectorSlideDuration);
  });

  $('.mobile-category-picker .category').on('click', function() {
    let value = $(this).dataAsString('value');

    if ($(this).hasClass('selected')) {
      $(this)
        .removeClass('selected')
        .closest('form').find('.category-picker-input').val('').attr('name', '');
    }
    else {
      $(this)
        .addClass('selected')
        .siblings('.category').removeClass('selected')
        .closest('form').find('.category-picker-input').val(value).attr('name', 'tag_4');
    }
  });

});
