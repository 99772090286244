$(document).ready(function() {

    $('.datepicker').pickadate()
    $('.timepicker').pickatime()

    var number = 0;

    function bindPictureUploadEvent(idx) {
        $(`#sortpicture${idx}`).on('change', function(e) {
            var file_data = e.currentTarget.files[0]; 
            
            if (file_data.size > 50 * Math.pow(2, 20) /* 50 MB */) {
                showMessageBox('danger', msg['ERR_FILE_TOO_LARGE'].replace(':size', '50 MB'));
                $(this).val(null);
            }
            else {
                var form_data = new FormData();                  
                form_data.append('file', file_data);
                form_data.append('type', 'einside');
                $.ajax({
                    url: 'https://ftp.dev.zero11.net/storage/einside/up.php', // point to server-side PHP script 
                    dataType: 'text',  // what to expect back from the PHP script, if anything
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: form_data,                         
                    type: 'post',
                    success: function(php_script_response) {
                        const data = JSON.parse(php_script_response);
                        if (data.status == 'OK') {
                            $(`#upload-div-${idx}`).addClass('hide');
                            $(`#image-uploaded-${idx}`).removeClass('hide');
                            $(`#link-image-${idx}`).val(data.link);
                            number++;
                        } else {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    },
                    error: function () {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                });
            }
        })
    }
    $('[id^="sortpicture"]').each(function() {
        let idx = $(this).attr('id').replace('sortpicture', '');
        bindPictureUploadEvent(idx);
    });

    $('#profile-consent-form').on('submit', function(event) {
        event.preventDefault();
        var $form = $(this);

        var formData = {
            'userConsents':[]
        };

        $('.profile-privacy-input').each(function () {
            var obj = {};
            obj.consentId = $(this).attr("name");
            obj.value = $(this).is(":checked");
            formData.userConsents.push(obj);
        });

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: 'application/json',
            beforeSend: function(data) {

            },
            success: function(data) {
                showMessageBox('success', msg['MSG_PROFILECONSENT_SAVED']);
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
            }
        });
    });

    $('#to-budget-private').on('click', function () {

        to_budget_private();

    });

    $('#back-personal-private').on('click', function () {

        to_personal_private();
        
    });

    $('#to-description-private').on('click', function () {

        to_description_private();

    });

    $('#to-send-private').on('click', function () {

        to_send_private(number);
        
    });

    $('.private-personal').on('click', function () {

        to_personal_private();
        
    });

    $('.private-budget').on('click', function () {

        to_budget_private();
        
    });

    $('.private-send').on('click', function () {

        to_send_private();
        
    });

    $('#contact-form [type="submit"]').on('click', function(event) {
        event.preventDefault();
        sendContact();
    });
});

function findCheckedInput(name) {

    var result = '';
    var x = document.getElementsByName(name);
    var i;
        for (i = 0; i < x.length; i++) {
            if (x[i].checked == true) {
                result = result.concat(x[i].value) + ' '    
            }
        }
    return result;
}

function sendRequestCaptcha() {
    $('#contact-no-quantity input[type=submit]').prop('disabled', false);
    sendRequest();
}

function sendRequest() {
    var $form = $('#contact-no-quantity :input, #contact-no-quantity textarea');

    if (document.getElementById('email-contact').value != "") {

    $.ajax({
        type: "POST",
        url: "/restful/delegate/form/save",
        data: $form.serialize() + '&quantity=' + $('.i-number').val(),
        dataType: 'json',
        contentType: 'application/x-www-form-urlencoded',
        beforeSend: function(data) {

        },
        success: function(data) {
            showMessageBox('success', msg['MSG_MESSAGE_SENT']);
        },
        failed: function(data) {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            grecaptcha.reset();
        },
        complete: function(data) {
        }
    });

    } else {
        showMessageBox('danger', msg['ERR_FILLEMAILFIELD']);
        grecaptcha.reset();
    }

}


function contactFormRecaptchaBusiness() {

    $('#contact-form-professionista').validate({
        errorPlacement: function(error, element) {
            error.appendTo("");
          }
    });

    if ($("#privacy-professionista").is(":checked") == true) {

    if ($('#contact-form-professionista').valid()) {

    $.ajax({
        type: $('#contact-form-professionista').attr('method'),
        url: $('#contact-form-professionista').attr('action'),
        data: $('#contact-form-professionista').serialize(),
        dataType: 'json',
        beforeSend: function(data) {
        },
        success: function(data) {
          if (data.success) {
            showMessageBox('success', msg['MSG_MESSAGE_SENT']);
          } else {
            showMessageBox('danger', data.errors.message);
          }
        },
        failed: function(data) {
          showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
          grecaptcha.reset();
        },
        complete: function(data) {
            
        }
    })
 
    } else {
        grecaptcha.reset();
    }

    } else {
        showMessageBox("danger", msg['ERR_PRIVACY'])
        grecaptcha.reset();
    }

}

function to_personal_private() {

    $('#private-contact-budget').addClass('hide');
    $('#private-contact-personal').removeClass('hide');
    $('#private-contact-send').addClass('hide');
    $('#private-contact-space-description').addClass('hide');

}

function to_budget_private() {

    $("#private-contact").validate({
        errorPlacement: function(error, element) {
        error.appendTo("");
        }
    });

    if ($("#private-contact").valid() == true) {

        $('#private-contact-budget').removeClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-send').addClass('hide');
        $('#private-contact-space-description').addClass('hide');

    }

}

function to_send_private(number) {

    $("#private-contact").validate({
        errorPlacement: function(error, element) {
        error.appendTo("");
        }
    });

    if ($("#private-contact").valid() == true) {

        $('#private-contact-budget').addClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-space-description').addClass('hide');
        $('#private-contact-send').removeClass('hide');

        document.getElementById('private-contact-name-lastname').innerHTML = $('#private-name').val();
        document.getElementById('private-contact-email').innerHTML = $('#private-email').val();
        document.getElementById('private-contact-phone').innerHTML = $('#private-phone').val();
        document.getElementById('private-contact-city').innerHTML = $('#private-city').val();

        document.getElementById('private-contact-description').innerHTML = findCheckedInput('rinnova');

        document.getElementById('private-contact-colors').innerHTML = $('#private-space-colors').val();
        document.getElementById('private-contact-budget-input').innerHTML = findCheckedInput('budget');

        if (number != 0) {

            $('#number-count').html($('#number-count').html() + number);

        } else {

            $('#private-image-div').addClass('hide');

        }

    }

}

function to_description_private() {

    $("#private-contact").validate({
        errorPlacement: function(error, element) {
        error.appendTo("");
        }
    });

    if ($("#private-contact").valid() == true) {

        $('#private-contact-budget').addClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-send').addClass('hide');
        $('#private-contact-space-description').removeClass('hide');

    }

}

function contactRecaptchaCallbackPrivate() {

    if ($("#privacy-private").is(":checked") == true) {

    $.ajax({
        type: $('#private-contact').attr('method'),
        url: $('#private-contact').attr('action'),
        data: $('#private-contact').serialize(),
        dataType: 'json',
        beforeSend: function(data) {
        },
        success: function(data) {
            if (data.success) {
            showMessageBox('success', msg['MSG_MESSAGE_SENT']);
            $('#private-contact-submit').addClass('private-button-disabled')
            } else {
            showMessageBox('danger', data.errors.message);
            }
        },
        failed: function(data) {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            $('#private-contact').foundation('close');
            grecaptcha.reset();
        },
        complete: function(data) {
            
        }
    })

    } else {
        showMessageBox("danger", msg['ERR_PRIVACY'])
        grecaptcha.reset();
    }
}

function contactFormRecaptcha() {
    if (document.getElementById('privacy').checked) {
        $('#contact-form [type=submit]').prop('disabled', false);
    } else {
        showMessageBox("danger", msg['ERR_PRIVACY']);
        grecaptcha.reset();
    }
}

function sendContact() {
    var $form = $('#contact-form');

    if (document.getElementById('email').value != "") {

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded',
            beforeSend: function(data) {
    
            },
            success: function(data) {
                showMessageBox('success', msg['MSG_MESSAGE_SENT']);
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                grecaptcha.reset();
            },
            complete: function(data) {
                $form.find('[type="submit"]').prop('disabled', true);
                grecaptcha.reset();
            }
        });

    } else {
        showMessageBox('danger', msg['ERR_FILLEMAILFIELD']);
        $form.find('[type="submit"]').prop('disabled', true);
        grecaptcha.reset();
    }

}