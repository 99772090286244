(function($) {
    $.fn.dataAsString = function(data_attr) {
        return this.first().data(data_attr) + '';
    };
    $.fn.dataAsNumber = function(data_attr) {
        return this.first().data(data_attr) >> 0;
    };
    $.fn.dataAsArray = function(data_attr, separator, mapping_fn) {
        separator = separator || ' ';
        let array = (this.first().data(data_attr) + '').split(separator);
        if (mapping_fn) {
            return array.map(mapping_fn);
        }
        else {
            return array;
        }
    };
}(jQuery));