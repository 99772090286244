
function showMessageBox(type, message, extra) {
    var boxType = type;
    var content = '';
    var topMessage = ''
    if (extra) {
        content = '<div>' + extra.content + '</div>';
    }
    if (boxType == 'danger') {
        topMessage = "ERROR"
    } else {
        topMessage = "SUCCESS" 
    }
    var messageBox = '<div class="alert-container fade in relative"><div class="alert-' + boxType + '"><img id="close-error" class="close-sample" src="/skins/current-skin/images/icons/cancel.svg" alt="close" loading="lazy"></img><img class="alert-image" src="/skins/current-skin/images/icons/' + boxType + '.svg" alt="icon" loading="lazy"></img><div class="alert">'+message+content+'!</div></div></div>';
    $('div.alert-box').html(messageBox);
    $('#close-error').on('click', function () {
        $('div.alert-box').html('');
        $('.alert-box').hide()
        $('body').removeClass('block-scroll')
    });
    $('body').addClass('block-scroll')
    $('.alert-box').show();
    setTimeout(function() {
        $('div.alert-box').html('');
        $('.alert-box').hide();
        $('body').removeClass('block-scroll')
    }, 3000);

}

function showQuestionBox(message, extra, action) {
    var content = '';
    if (extra) {
        content = '<div>' + extra.content + '</div>';
    }
    var messageBox = '<div class="alert-container fade in relative"><div class="alert-danger"><img id="close-error" class="close-sample" src="/skins/current-skin/images/icons/cancel.svg" alt="close" loading="lazy"></img><img class="alert-image" src="/skins/current-skin/images/icons/danger.svg" alt="danger" loading="lazy"></img><div class="alert">'+message+content+'</div><div class="flex mt-16"><input type="button" class="btn btn-third width-100 mr-8 close-button" value="Annulla"><input type="button" onclick="' + action + '" class="btn btn-primary width-100 ml-8" value="Conferma"></div></div></div>';
    $('div.alert-box').html(messageBox);
    $('#close-error, .close-button').on('click', function () {
        $('div.alert-box').html('');
        $('.alert-box').hide()
        $('body').removeClass('block-scroll')
    });
    $('body').addClass('block-scroll')
    $('.alert-box').show();

}

$(document).ready(function() {
    
    var errorFound = getUrlParam("error");

    if (errorFound) {
        $('body').addClass('block-scroll')
        $('.alert-box').show();
        $('.close-sample').on('click', function () {
            $('div.alert-box').html('');
            $('.alert-box').hide();
            $('body').removeClass('block-scroll')
        });
        setTimeout(function() {
            $('div.alert-box').html('');
            $('.alert-box').hide();
            $('body').removeClass('block-scroll')
        }, 3000);
    }

});