$(document).ready(function () {

    $('.checkbox-container').each(function() {
        var height = $(this).find('label').outerHeight() + 8;
        $(this).attr('style', 'height: ' + height + 'px;');
    });

    $('#newsletter-subscribe input[type="email"]').on('input', function() {
        hideStep2();
    });

    $('#newsletter-subscribe .btn-next').on('click tap', function(e) {
        e.preventDefault();
        $('#checkbox3').parent().find('label').removeClass('red');
        if ($('#newsletter-subscribe input[type="email"]').valid() && $('#checkbox3').is(':checked')) {
            showStep2();
        }
        else {
            $('#newsletter-subscribe').trigger('submit');
        }
    });

    $('.newsletter-subscribe-landing input[type="email"]').on('keyup', function(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        if (event.keyCode == 13 /* ENTER */) {
            if ($(this).valid()) {
                showStep2();
            }
        }
    });

    $('#newsletter-arrow').on('click tap', function() {
        if ($('#newsletter-subscribe input[type="email"]').valid()) {
            showStep2();
        }
        else {
            $('#newsletter-subscribe').trigger('submit');
        }
    });

    $('#newsletter-step-2').hide();
    $('#newsletter-subscribe button[type=submit]').hide();

    function showStep2() {
        if ($('.newsletter-subscribe-landing').length > 0) {
            $('#newsletter-step-2').slideDown(400);
        }
        else {
            $('#newsletter-subscribe .btn-next').hide(0, () => {
                $('#newsletter-step-2').slideDown(400);
            });
        }

        const recaptcha = $('#newsletter-subscribe .g-recaptcha');
        if (!recaptcha.data('loaded')) {
            $.ajax({
                url: 'https://www.google.com/recaptcha/api.js',
                dataType: 'script',
                async: true,
                success: function() {
                    recaptcha.data('loaded', 'true');
                }
            })
        }
    }

    function hideStep2() {
        $('#newsletter-step-2').slideUp(400, () => {
            $('#newsletter-subscribe .btn-next').show();
        });
    }

    $('#newsletter-subscribe').on("submit", function(e) {
        e.preventDefault();

        var $form = $('#newsletter-subscribe');

        $('#checkbox3').parent().find('label').removeClass('red');
        if (! $('#checkbox3').prop('checked')) {
            $('#checkbox3').parent().find('label span, label a').addClass('red');
            return;
        }

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {

            },
            success: function(data) {
                if (data.success) {
                    $('#newsletter-step-2').hide();
                    $('#newsletter-subscribe .email-input').val('');
                    showMessageBox('success', msg['MSG_NEWSLETTER_COMPLETED'])

                    // landing page
                    if ($('#newsletter-subscribe').hasClass('newsletter-subscribe-landing')) {
                        var baseUrl = window.location.href;
                        if (baseUrl.endsWith('/')) {
                            baseUrl = baseUrl.slice(0, -1);
                        }
                        baseUrl = baseUrl.split('/').slice(0, -1).join('/');
                        setTimeout(() => window.location.href = baseUrl + '/landing-countdown-subscribe-done', 2000);
                    }
                }
                else {
                    if (data.errors.message == 'Invalid email')
                        showMessageBox('danger', msg['ERR_INVALIDMAIL']);
                    else if (data.errors.message == 'Invalid captcha code')
                        showMessageBox('danger', msg['MSG_INVALID_CAPTCHA']);
                    else if (data.errors.message == 'Login to your account')
                        showMessageBox('danger', msg['MSG_USER_ALREADY_REGISTERED'])
                    else
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    expiredNewsletterCaptcha();
                }
            },
            error: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                expiredNewsletterCaptcha();
            },
            complete: function(data) {
                if (! $('#newsletter-subscribe').hasClass('newsletter-subscribe-landing')) {
                    grecaptcha.reset();
                }
            }
        });
    });

});


function subscribeNewsletterCaptcha() {
    $('#newsletter-subscribe button[type="submit"]').show(400);
}

function expiredNewsletterCaptcha() {
    $('#newsletter-subscribe button[type="submit"]').hide(400);
}