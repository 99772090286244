$(document).ready(function() {

    var lastCategory = ''

    $('.js-open-submenu').on('click', function() {
        $('#mobile-menu-logo').fadeOut(0);
        var title = $(this).text();
        lastCategory = title;
        $('#mobile-menu-title').html(title);
        $('#mobile-menu-title').fadeIn(0);
    })

    $('.open-subcategory').on('click', function() {
        $('#mobile-menu-title').fadeOut(0);
        var title = $(this).text();
        $('#mobile-menu-title').html(title);
        $('#mobile-menu-title').fadeIn(0);
    })

    $('.open-subcategory').on('click', function() {
        var sub = $(this).attr('data-open-sub');
        $('.js-mobile-menu-back').hide();
        $('.js-mobile-menu-back-sub').show();
        $(this).fadeOut(0);
        $(this).siblings().fadeOut(0);
        $(this).addClass('last-closed');
        $('div[data-sub=' + sub + ']').fadeIn(0);
    })

    $('.js-mobile-menu-back-sub').on('click', function () {

        $('.js-mobile-menu-back').show();
        $('.js-mobile-menu-back-sub').hide();

        $('#mobile-menu-title').fadeOut(0);
    
        var cat = $('.last-closed');
        var sub = cat.attr('data-open-sub');
        $('div[data-sub=' + sub + ']').fadeOut(0);
        $('.last-closed').removeClass('last-closed');
        $('#mobile-menu-title').html(lastCategory);
        $('#mobile-menu-title').fadeIn(0);
        cat.fadeIn(0);
        cat.siblings().fadeIn(0);
    
    });

    $('.js-open-submenu').on('click', function (e) {
        e.preventDefault();
        var submenuId = $(this).data('open');
        $('.js-mobile-menu').fadeOut(0);
        $('.close-offcanvas-menu').hide();
        $('#' + submenuId).fadeIn(0);
        $('.js-mobile-menu-back').fadeIn(0);
    });
    $('.js-mobile-menu-back').on('click', function (e) {
        $('#mobile-menu-title').fadeOut(0)
        e.preventDefault();
        $('.js-submenu').fadeOut(0);
        $('.js-mobile-menu-back').hide();
        $('#mobile-menu-logo').fadeIn(0);
        $('.js-mobile-menu').fadeIn(0);
        $('.close-offcanvas-menu').fadeIn(0); 
    });

    $('#mobile-menu-trigger').on('click', function () {
        $(this).children('img').toggleClass('hidden');
        $('.mobile-menu, .mobile-menu__search').fadeToggle();
        $('body').toggleClass('overflow-hidden');
    });

    $('.mobile-menu__language').on('click', function () {
        $(this).siblings('ul').slideToggle();
    });
    
    // menu
    $('.is-hover a').on('mouseover', function () {
        var idx = $(this).parent().index() + 2;
        $('.info:nth-child(' + idx + ')').addClass('info-visible');
        $('.info:nth-child(' + idx + ')').siblings().removeClass('info-visible');
      });
      
      $('.dropdown').on('mouseout', function () {
        $('.info:nth-child(1)').addClass('info-visible').siblings().removeClass('info-visible');
      });

    //icone menu
    $(".menu-element") .mouseover(function () {
        $(this).find('.menu-img').addClass('hide')
        $(this).find('.menu-img-selected').removeClass('hide')
     }).mouseout(function () {
        $(this).find('.menu-img-selected').addClass('hide')
        $(this).find('.menu-img').removeClass('hide')
     });
});
