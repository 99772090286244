$(document).ready(function() {
    'use strict';
    
    $('.zoom-product')
    .wrap('<span style="display:inline-block"></span>')
    .css('display', 'block')
    .parent()
    .zoom()

    function checkSingleOption() {

        $('.size-select').each(function() {
            if ($(this).children('option:not("[disabled]")').length == 1) {
                $(this).val($(this).children('option:not("[disabled]")').val())
                let selectedIds = $(this).children('option:selected.product-option').data('product-id');
                $(this).data('selected-ids', selectedIds);
        
                let product = $(this).closest('.product');
                let options = $(this).closest('form').find('.size-select');
                checkCompatibleOptions(options);
                selectModel(product, options);
                selectModel($('#addToCartComplete'), options);
            }
        })
    

    }

    checkSingleOption();

    $('.size-select').on('change', function() {
        let selectedIds = $(this).children('option:selected.product-option').data('product-id');
        $(this).data('selected-ids', selectedIds);

        let product = $(this).closest('.product');
        let options = $(this).closest('form').find('.size-select');
        checkCompatibleOptions(options);
        selectModel(product, options);
        selectModel($('#addToCartComplete'), options);
        checkSingleOption();
    });

    function checkCompatibleOptions(options) {
        /**
         * e.g. intersect([1,2,3], [2,4,3]) => [2,3]
         * @param  {...any[]} arrays 
         */
        function intersect(...arrays) {
            if (arrays.length == 0)
                return [];

            let result = arrays[0];
            for (let i = 1; i < arrays.length; i++) {
                result = result.filter(elem => arrays[i].includes(elem));
            }
            return result;
        }
        
        $(options).each(function() {
            const thisOption = $(this);
            let otherOptions = $(options).toArray()
                .filter(option => thisOption.attr('name') != $(option).attr('name') && $(option).data('selected-ids') != null);
            
            if (otherOptions.length > 0) {
                let availableIds = otherOptions
                    .map(option => $(option).dataAsString('selected-ids'))
                    .filter(ids => ids)
                    .map(ids => ids.split(' '));
                availableIds = intersect(...availableIds);
    
                thisOption.find('.product-option').each(function() {
                    let ids = $(this).dataAsArray('product-id', ' ');
                    if (intersect(ids, availableIds).length == 0) {
                        $(this).prop('disabled', true);
                    }
                    else {
                        $(this).prop('disabled', false);
                    }
                });
            }
        });
    }

    function selectModel(product, options) {
        let selectedIds = $(options).toArray().map(option => $(option).dataAsArray('selected-ids', ' '));

        let filteredId = [];
        for (let i = 0; i < selectedIds.length; i++) {
            let ids = selectedIds[i];

            if (!ids)
                return;
            
            if (i == 0) {
                filteredId.push(...ids);
            }
            else {
                filteredId = filteredId.filter(id => ids.includes(id));
            }
        }

        if (filteredId.length == 1) {
            const $product = $(product);
            const modelId = filteredId[0];

            $product.find('.option').addClass('hide');
            $product.find(`.option-${modelId}`).removeClass('hide');

            const modelMax = $product.find(`.model-max[data-model="${modelId}"]`);
            const max = modelMax.data('max');
            const matomo = modelMax.data('matomo');
            $product.find('.quantity-container')
                .data('max', max)
                .data('matomo', matomo)
                .trigger('keydown');

            $product.find('.modelFromOptions').val(`qty_model_${modelId}`);
            $('#product-slider').slick('slickUnfilter')

            if ($(`[data-models*="${modelId}"]`).length > 0) {
                $('#product-slider').slick('slickFilter', `[data-models*="${modelId}"]`);
                $('#product-slider-nav').slick('slickUnfilter').slick('slickFilter', `[data-models*="${modelId}"]`);
            }
            else {
                $('#product-slider').slick('slickFilter', '[data-models=""]');
            }
        }
    }

    $('.clear-options').on('click', function() {
        $('#product-slider').slick('slickUnfilter').slick('slickFilter', '[data-models=""]');
        $('#product-slider-nav').slick('slickUnfilter').slick('slickFilter', '[data-models=""]');
        
        let options = $(this).closest('form').find('.size-select');
        options.each(function() {
            $(this)
                .children('option').prop('selected', false)
                .not('.product-option').prop('selected', true);
            $(this)
                .children('.product-option').prop('disabled', false);
        });

        let product = $(this).closest('.product');
        product.find('.option').addClass('hide');
        product.find('.option.default').removeClass('hide');
        product.find('.modelFromOptions').val('');

        $(this).closest('.product-options').find('.size-select').each(function() {
            $(this).data('selected-ids', '');
        });
    });

    $('.close-popup').on('click', function() {
        $('#addToCartComplete').foundation('close');
    })

    $('#close-modal').on('click', function () {
        $('#sample-modal').foundation('close');
    });

    $('#close-modal-not-available').on('click', function () {
        $('#contact-no-quantity').foundation('close');
    });

    // Add to cart
    // Add to cart - cart popup-
    $('#catalog-container, .product-container').on('submit', '.addtocart-form', function(event) {
        event.preventDefault();
        var form = $(this);

        var singleSize = form.find('.nosize').val();
        var selected = form.closest('.product').find('.modelFromOptions').val();
        if (singleSize == 'false' && selected == '') {
            showMessageBox('danger', general_choosesize);
        }
        else {
            if (singleSize == 'true') {
                if (form.attr('remove') == 'true') {
                    var data = form.find('.nosize-input').attr('name') + '=' + 0
                } else {
                    var data = form.find('.nosize-input').attr('name') + '=' + form.find('.i-number').val()
                }
            } else {
                var data = selected + '=' + form.find('.i-number').val()
            }

            $.ajax({
                type: form.attr('method'),
                url: form.attr('action'),
                data: data,
                headers: {'Accept': 'application/json'},
                success: function(data) {
                    if (!data.success) {
                        if (data.code == 'MIXED_INTANGIBLE') {
                            showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                        }
                        else if (data.code == 'INSUFFICIENT_STOCK') {
                            showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                        }
                        else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                            showMessageBox('danger', msg['MSG_RULE_REJECT'].replace(/:rule/g, data.rejectCartRuleName))
                        }
                        else if (data.code == 'MIXED_CART') {
                            showMessageBox('danger', msg['MSG_MIXED_CART']);
                        }
                        else if (data.code == 'LOGIN_REQUIRED') {
                            location.href = '/' + $('html').attr('lang') + '/login';
                        }
                        else { 
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    }
                    else {
                        rewixUpdateCart(form);
                        //Show popup
                        var name = form.closest('.row').find('.product-item__brand').html();
                        var code = form.closest('.row').find('#codeForReveal').html();
                        var price = form.closest('.row').find('.product-info__single-price').parent().html();
                        if (form.closest('.row').find('.product-item__img').find('img')[0] != undefined) {
                            var imageSrc = form.closest('.row').find('.product-item__img').find('img')[0].src
                            $('#imageReveal').attr('src', imageSrc);
                        }
                        //$('#modelReveal').html(size);
                        $('#nameReveal').html(name);
                        $('#codeReveal').html(code);
                        $('#priceReveal').addClass('product-info__price popup-cart').html(price).find('.list').removeClass('list');
                        $.ajax({
                            type : "GET",
                            beforeSend : function(xhr) {
                                xhr.setRequestHeader("Accept", "application/xml")
                            },
                            url : '/restful/shopping/cart',
                            dataType : "xml",
                            error : function(xh, txt, err) {
                                alert(txt);
                            },
                            success : function(data) {
                                $('#addToCartComplete').foundation('open')
                            }
                        })
                    }
                },
                error: function(xhr, textStatus, errorThrown) {
                    if ($('#contact-no-quantity').length > 0) {
                        $('#contact-no-quantity').foundation('open');
                    } else {
                        showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                    }
                }
            });
        }
    });

    $('#sample-modal').on('closed.zf.reveal', function() {
        $('.sample-slick').slick('unslick');
    });
});

function vimeoLoadingThumb(id){    
    var url = "https://vimeo.com/api/v2/video/" + id + ".json?callback=showThumb";

    var id_img = "#vimeo-" + id;

    var script = document.createElement( 'script' );
    script.src = url;

    $(id_img).before(script);
}


function showThumb(data){
    var id_img = "#vimeo-" + data[0].id;
    $(id_img).attr('src',data[0].thumbnail_medium);
}

function loadQtyInputs() {
    $('.i-number').each(function() {
        const $this = $(this);

        if (!$this.data('initialized')) {
            $('<button class="i-number-btn i-number-minus" tabindex="-1"><img src="/skins/current-skin/images/icons/qty-min.svg" alt="minus" loading="lazy"/></button>')
                .insertBefore($this)
                .on('click', function(event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value - 1);
                });
            
            $('<button class="i-number-btn i-number-plus" tabindex="-1"><img src="/skins/current-skin/images/icons/qty-plus.svg" alt="plus" loading="lazy"/></button>')
                .insertAfter($this)
                .on('click', function(event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value + 1);
                });
    
            $this.on('keydown input', function(event) {
                const value = getCurrentValue($this);
                const key = (event.key || '').toUpperCase();

                if (key == 'ENTER') {
                    event.preventDefault();
                    $this.closest('form').trigger('submit');
                }
                else if (key == 'ARROWUP') {
                    checkValue(value + 1);
                }
                else if (key == 'ARROWDOWN') {
                    checkValue(value - 1);
                }
                else {
                    checkValue(value);
                }
            });

            $this.data('initialized', true);
    
            function getCurrentValue($this) {
                return $this.val().toString().replace(/[^0-9]/g, '') >> 0;
            }
    
            function checkValue(value) {
                const min = $this.data('min') >> 0;
                
                if (value < min) {
                    $this.val(min);
                }
                else {
                    $this.val(value);
                }
                $this.trigger('change');
            }
        }
    });
}