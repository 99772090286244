$(window).on('load', function() {
  
    if (!((parent != undefined) && (parent.window != undefined) && (parent.window.it != undefined)))
  

    $('#img-slider').slick({
        centerMode: true,
        centerPadding: '0px',
        autoplay: true,
        autoplaySpeed: 9000,
        arrows: true,
        slidesToShow: 3,
        pauseOnFocus: false,
        dots: true,
        variableWidth: true,
        focusOnSelect: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    variableWidth: false,
                }
                },
              
        ]
    });

    $('#related-slider').slick({
        infinite: true,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        pauseOnFocus: false,
        nextArrow: '<img class="product-slide-arrow arrow-right" src="/skins/current-skin/images/product-arrow-right.svg" alt="arrow right" loading="lazy" />',
        prevArrow: '<img class="product-slide-arrow arrow-left" src="/skins/current-skin/images/product-arrow-left.svg" alt="arrow left" loading="lazy" />',
        responsive: [
                {
                breakpoint: 1024,
                    settings: {
                    slidesToShow: 3
                    }
                },
                {
                breakpoint: 640,
                    settings: {
                    slidesToShow: 1
                }
            }
            ]
    });


    $('#product-slider').slick({
        speed: 500,
        autoplay: false,
        arrows: true,
        autoplaySpeed: 4500,
        pauseOnFocus: false,
        infinite: true,
        rows: 0
    });

    $('#product-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        $('#product-slider-nav').slick('slickGoTo', nextSlide);
    });

    // $('#product-slider').slick('slickFilter', '[data-models=""]');

    $('#product-slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        asNavFor: '#product-slider',
        dots: true,
        focusOnSelect: true,
        infinite: true,
        rows: 0
    });

    // $('#product-slider-nav').slick('slickFilter', '[data-models=""]');


});