$(document).ready(function() {
    'use strict';

    $('.tag_4').change( function() {
      if ($(this).val() != '') {
          $(this).attr('name', 'tag_4')
      } else {
          $(this).attr('name', '')
      }
    })

    $('.search-icon').on('click', function() {
      $(this).parents('form').trigger('submit')
    })

    $(".nano").nanoScroller()                                                                                                                                                        
    $(".nano-pane").css("display","block");                                                                                                                                          
    $(".nano-slider").css("display","block");  

    var search = unescape(getUrlParam('search'));
    search = search.replace('+', ' ');
    if (search != "undefined") {
      $('#search-keyword').html(search);
      $('#search-row').removeClass('hide');
      $('#search-ok').html(search);
      $('.no-search-found').removeClass('hide');
    } else {
      $('.no-products-found').removeClass('hide');
    }
    

    // catalog layout
    function checkPreferredLayout() {
        var layout = localStorage.getItem('catalogLayout');
        if (layout && layout === 'list') {
            $('#list-option').prop('checked', true).trigger('click');
        } else {
            layout = 'grid';
            $('#grid-option').prop('checked', true).trigger('click');
        }

        setCatalogLayout(layout);
    }

    function setCatalogLayout(layout) {
        if (layout === 'grid') {
            $('#catalog-wrapper .list-layout').hide();
            $('#catalog-wrapper .grid-layout').fadeIn();
        } else {
            $('#catalog-wrapper .grid-layout').hide();
            $('#catalog-wrapper .list-layout').fadeIn();
        }

        localStorage.setItem('catalogLayout', layout);
    }

    if (Foundation.MediaQuery.atLeast('large')) {
      if ($('.filters-wrapper').length > 0) {
        $('.filters-wrapper').slideToggle()
        $('#arrow-filter').toggleClass('rotated')
      }
    }

    $('#toogle-filter').on('click', function () {
      $('.filters-wrapper').slideToggle()
      $('#arrow-filter').toggleClass('rotated')
    });


    // catalog filters
    $('#catalog-container').on('click', '.catalog-filter', function(event) {
        event.preventDefault();
        var url = $(this).attr('href');
        reloadCatalog(url, true);
    });

    function reloadCatalog(url, push) {
        $('#catalog-container').addClass('catalog-loading');
        $.ajax({
            url: url,
            type: "GET",
            dataType: "html",
            complete: function(jqXHR, textStatus){
                var response = jqXHR.responseText;

                $('#catalog-container').html($("<div>").append($.parseHTML(response)).find("#catalog-wrapper"));

                if (push) {
                    window.history.pushState(null, document.title, url);
                }
                populateRemoveFilters();
                checkPreferredLayout();
                loadQtyInputs();
                addClickEvent();
                $('.filters-wrapper').show();
                $('#catalog-container').removeClass('catalog-loading');
                $('html, body').removeClass('is-popup-open');
                $(".nano").nanoScroller()                                                                                                                                                        
                $(".nano-pane").css("display","block");                                                                                                                                          
                $(".nano-slider").css("display","block");  

                $('#catalog-wrapper').on('click', '#filters-dropdown .apply-size-filter', function(){
                  var tags = {};
                  var baseUrl = $(this).prev().children("ul").data("baseurl");
              
                  $(this).prev().find(".active").each(function(){
                    var liTag = $(this);
                    do{
                      var tagId = "size";
                      var tagArray = tags[tagId];
                      if (!tagArray){
                        tagArray = []
                        tags[tagId] = tagArray;
                      }
                      var tagValue = $(liTag).data("tagvalue");

                      tagArray.push(tagValue);

                      if ($(liTag).parent().data("baseurl")){
                        liTag = false;
                      }else{
                        liTag = liTag.parent().closest("li");
                      }
                    }while(liTag && liTag.length > 0);
                  });
              
                  var url;
                  if (Object.keys(tags).length > 0){
                    if (baseUrl.indexOf("?") < 0){
                      url = baseUrl + '?' + jQuery.param(tags, true);
                    }else{
                      url = baseUrl + "&" + jQuery.param(tags, true);
                    }
                  }else{
                    url = baseUrl;
                  }
              
                  reloadCatalog(url, true);
                });
            
                $('#catalog-wrapper').on('click', '#filters-dropdown .apply-filter', function(){
                    var tags = {};
                    var baseUrl = $(this).prev().children("ul").data("baseurl");
                
                    $(this).prev().find(".active").each(function(){
                      var liTag = $(this);
                      do{
                        var tagId = "tag_" + $(liTag).parent().data("tagid");
                        var tagArray = tags[tagId];
                        if (!tagArray){
                          tagArray = []
                          tags[tagId] = tagArray;
                        }
                        var tagValue = $(liTag).data("tagvalue");
                        if (tagArray.indexOf(tagValue) < 0){
                          tagArray.push(tagValue);
                        }
                        if ($(liTag).parent().data("baseurl")){
                          liTag = false;
                        }else{
                          liTag = liTag.parent().closest("li");
                        }
                      }while(liTag && liTag.length > 0);
                    });
                
                    var url;
                    if (Object.keys(tags).length > 0){
                      if (baseUrl.indexOf("?") < 0){
                        url = baseUrl + '?' + jQuery.param(tags, true);
                      }else{
                        url = baseUrl + "&" + jQuery.param(tags, true);
                      }
                    }else{
                      url = baseUrl;
                    }
                
                    reloadCatalog(url, true);
                });
                
                $('#catalog-wrapper').on('click', '#filters-dropdown .toggle-subfilter', function(event) {
                event.preventDefault();
                $(this).parent('li').toggleClass('open');
                $(this).closest('.scroll-view').nanoScroller({ alwaysVisible: true });                                                                                                       
                $(".nano-pane").css("display","block");                                                                                                                                          
                $(".nano-slider").css("display","block");  
                return false;
                });

            }
        });
    }

    function resetDropdown($excluded){
        var $dropDowns = $('#catalog-wrapper #filters-dropdown .filter-dropdown').not($excluded);
        $dropDowns.slideUp();
        $dropDowns.parent().removeClass("open");
        $dropDowns.find(".active").not(".active-original").removeClass("active");
        $dropDowns.find(".active-original").not(".active").addClass("active");
      }

    function populateRemoveFilters() {
        $('.filters-wrapper li.active > a').each(function() {
            var id = $(this).data('ref');
            var url = $(this).attr('href');
            $(document.getElementById(id)).attr('href', url);
        });
        $('.filters-wrapper > a').each(function() {
            if ($(this).attr('href') === '#') {
                $(this).addClass('disabled');
                $(this).children('span.fa').remove();
            }
        });
    }

    function addClickEvent() {
        $('#filters-trigger').on('click', function () {
            $('.filters-wrapper').slideToggle();
            $('body').toggleClass('open-filter');
        });

        $('.dropdown-slide').on('click', function () {
            $(this).siblings('.subfilters-list').slideToggle();
            $(this).children('img').toggleClass('hide');
        });

        $('#layout-options input[type="radio"]').on('click', function() {
            setCatalogLayout(this.id.indexOf('grid') !== -1 ? 'grid' : 'list');
        });
    }

    $('html').on('click', function() {
      resetDropdown($(this).parents(".filter-dropdown"));
    });

    $('#catalog-container').on('click', '#filters-dropdown .filter-name', function(){
    var $thisScrollView = $(this).next(".filter-dropdown");
    resetDropdown($thisScrollView);
    if (!$(this).parent().hasClass("open")) {
    $thisScrollView.slideDown();
    $(this).parent().addClass("open");
    window.dispatchEvent(new Event('resize')); //Hack to make nanoscroller work
    } else {
    $thisScrollView.slideUp();
    $(this).parent().removeClass("open");
    }
    return false;
    });

    $('#catalog-wrapper').on('click', '#filters-dropdown .apply-filter', function(){
        var tags = {};
        var baseUrl = $(this).prev().children("ul").data("baseurl");
    
        $(this).prev().find(".active").each(function(){
          var liTag = $(this);
          do{
            var tagId = "tag_" + $(liTag).parent().data("tagid");
            var tagArray = tags[tagId];
            if (!tagArray){
              tagArray = []
              tags[tagId] = tagArray;
            }
            var tagValue = $(liTag).data("tagvalue");
            if (tagArray.indexOf(tagValue) < 0){
              tagArray.push(tagValue);
            }
            if ($(liTag).parent().data("baseurl")){
              liTag = false;
            }else{
              liTag = liTag.parent().closest("li");
            }
          }while(liTag && liTag.length > 0);
        });
    
        var url;
        if (Object.keys(tags).length > 0){
          if (baseUrl.indexOf("?") < 0){
            url = baseUrl + '?' + jQuery.param(tags, true);
          }else{
            url = baseUrl + "&" + jQuery.param(tags, true);
          }
        }else{
          url = baseUrl;
        }
    
        reloadCatalog(url, true);
      });

    $('#catalog-wrapper').on('click', '#filters-dropdown .apply-size-filter', function(){
        var tags = {};
        var baseUrl = $(this).prev().children("ul").data("baseurl");
    
        $(this).prev().find(".active").each(function(){
          var liTag = $(this);
          do{
            var tagId = "size";
            var tagArray = tags[tagId];
            if (!tagArray){
              tagArray = []
              tags[tagId] = tagArray;
            }
            var tagValue = $(liTag).data("tagvalue");

            tagArray.push(tagValue);

            if ($(liTag).parent().data("baseurl")){
              liTag = false;
            }else{
              liTag = liTag.parent().closest("li");
            }
          }while(liTag && liTag.length > 0);
        });
    
        var url;
        if (Object.keys(tags).length > 0){
          if (baseUrl.indexOf("?") < 0){
            url = baseUrl + '?' + jQuery.param(tags, true);
          }else{
            url = baseUrl + "&" + jQuery.param(tags, true);
          }
        }else{
          url = baseUrl;
        }
    
        reloadCatalog(url, true);
      });
    
    $('#catalog-wrapper').on('click', '#filters-dropdown .toggle-subfilter', function(event) {
    event.preventDefault();
    $(this).parent('li').toggleClass('open');
    $(this).closest('.scroll-view').nanoScroller({ alwaysVisible: true });                                                                                                       
    $(".nano-pane").css("display","block");                                                                                                                                          
    $(".nano-slider").css("display","block");  
    return false;
    });

    $('#catalog-container').on('click', '#filters-dropdown li > a', function(event){
      var count = $('#filters-dropdown li.active').length;
      if (count >= 12 && !$(this).parent().hasClass('active')) {
        showMessageBox('warning',general_toomanyfilters);
        $('#filters-dropdown').focus();
      } else {
        $(this).parent().toggleClass('active');
      }
      return false;
    });

    populateRemoveFilters();
    checkPreferredLayout();
    loadQtyInputs();
    addClickEvent();

    // search result
    if (GetUrlValue('search') != undefined) {
      if (document.getElementById('search-result') != undefined) {
        $('#search-result').removeClass('hide');
        document.getElementById('results-search').innerHTML = decodeURIComponent(GetUrlValue('search'));
      }
    } else {
      if ($('#search-result') != undefined) {
      $('#search-result').addClass('hide');
      }
    }

});

function getUrlParam(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
}